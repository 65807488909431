import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {Container, Grid, Typography, Card, CardContent, CardMedia, IconButton, Box} from '@mui/material';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

function App() {

  const _launchMail = () => {
    window.location.href = "mailto:service@doolaku.com.tw";
  };

  return (
    <Router>
      <Container maxWidth="lg">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box py={4}>
            <img src="/image/app-name.png" alt="Dooloaku" height={'40px'}/>
          </Box>
        </Link>
      </Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <Container maxWidth={false} style={{ backgroundColor: '#efefef' }}>
        <Box py={10} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
          <img src="/image/app-name.png" alt="Dooloaku" height={'30px'}/>
          <Grid container spacing={3} justifyContent="center" marginTop={2}>
            <Grid item>
              <Link to="/">首頁</Link>
            </Grid>
            <Grid item>
              <Link to="/privacy-policy">隱私權政策</Link>
            </Grid>
            <Grid item>
              <Link to="/terms-of-use">服務條款</Link>
            </Grid>
          </Grid>
          <Typography maxWidth={'md'} align={'center'} mt={10}>
            果承有限公司 版權所有 © 2024 <br/>
            統一編號：97562300，登記地址：新北市土城區中央路3段210之20號6樓  <br/>
          </Typography>
          <Typography maxWidth={'md'} align={'center'} mt={2}>
            如對本App有帳號申請與操作相關問題，歡迎聯絡客服中心
            <Typography href="#" onClick={_launchMail} sx={{color: 'blue', textDecoration: 'underline', fontSize: 16}}>
              service@doolaku.com.tw
            </Typography>
          </Typography>
        </Box>
      </Container>
    </Router>
  );
}

export default App;
