import React from 'react';
import {Container, Grid, Typography, Card, CardContent, CardMedia, IconButton, Box, Hidden} from '@mui/material';
import bgMain from '../bg-main.svg';
import bgDownload from '../bg-download.svg';

function Home() {
  return (
    <>
      <Box style={{
        backgroundImage: `url(${bgMain})`,
        backgroundSize: 'auto',
        width: '100vw',
      }}>
        <Container maxWidth={'lg'} sx={{p: 4}}>
          <Grid container spacing={3} justifyContent="center">
            <Hidden mdDown>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <Typography variant="h1">1</Typography>
                  <Typography variant="h4">輸入貨運需求</Typography>
                  <Typography variant="body2" color="textSecondary">
                    在App上輸入您的貨物信息，點擊想要的日期，以及預計的運輸時間。
                  </Typography>
                </Box>
                <Box mt={10}>
                  <Typography variant="h1">3</Typography>
                  <Typography variant="h4">貨車動態</Typography>
                  <Typography variant="body2" color="textSecondary">
                    貨物送達後接收通知，即時掌握取貨情況，追蹤狀況。
                  </Typography>
                  <img src="/image/screenshot-notify.png" alt="screenshot-profile" width={'100%'}/>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <img src="/image/screenshot-home.png" alt={'screenshot-home'} width={'100%'}/>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box mt={10}>
                  <Typography variant="h1">2</Typography>
                  <Typography variant="h4">獲取媒合選項</Typography>
                  <Typography variant="body2" color="textSecondary">
                    我們將為您提供多個回頭車選項，根據您的需求匹配最合適的運輸資源。
                  </Typography>
                  <Box mt={4}/>
                  <Typography variant="body2" color="textSecondary">
                    每位司機的評價一目了然，幫助您做出最佳選擇。
                  </Typography>
                  <img src="/image/screenshot-profile.png" alt="screenshot-profile" width={'100%'}/>
                </Box>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <img src="/image/screenshot-home.png" alt={'screenshot-home'} width={'100%'}/>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <Typography variant="h1">1</Typography>
                  <Typography variant="h4">輸入貨運需求</Typography>
                  <Typography variant="body2" color="textSecondary">
                    在App上輸入您的貨物信息，點擊想要的日期，以及預計的運輸時間。
                  </Typography>
                </Box>
                <Box mt={10}>
                  <Typography variant="h1">2</Typography>
                  <Typography variant="h4">獲取媒合選項</Typography>
                  <Typography variant="body2" color="textSecondary">
                    我們將為您提供多個回頭車選項，根據您的需求匹配最合適的運輸資源。
                  </Typography>
                  <Box mt={4}/>
                  <Typography variant="body2" color="textSecondary">
                    每位司機的評價一目了然，幫助您做出最佳選擇。
                  </Typography>
                  <img src="/image/screenshot-profile.png" alt="screenshot-profile" width={'100%'}/>
                </Box>
                <Box mt={10}>
                  <Typography variant="h1">3</Typography>
                  <Typography variant="h4">貨車動態</Typography>
                  <Typography variant="body2" color="textSecondary">
                    貨物送達後接收通知，即時掌握取貨情況，追蹤狀況。
                  </Typography>
                  <img src="/image/screenshot-notify.png" alt="screenshot-profile" width={'100%'}/>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <Box style={{
        backgroundImage: `url(${bgDownload})`,
        backgroundSize: 'cover',
        width: '100vw',
      }}>
        <Container maxWidth={'lg'} sx={{p: 4}}>
          <Grid container spacing={3} sx={{marginTop: 3}} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <img src="/image/screenshot-report.png" alt="screenshot-profile" width={'100%'}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" align="center"
                          color={'white'}>立即使用我們的服務<br/>享受高效又實惠的物流體驗！</Typography>
              <Grid container spacing={2} sx={{marginTop: 2, padding: 4}}>
                <Grid item xs={12} sm={6} container justifyContent={'center'}>
                  <Typography variant="h6" align="center" color={'white'}>下載業主版</Typography>
                  <img src="/image/qrcode-android-boss.png" alt="Google Play" style={{width: '100%'}}/>
                  <img src="/image/download-android.png" alt="Google Play" style={{width: '100%', cursor: 'pointer'}}
                       onClick={()=>{
                          window.open('https://play.google.com/store/apps/details?id=com.doolaku.boss', '_blank');
                       }}/>
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent={'center'}>
                  <Typography variant="h6" align="center" color={'white'}>下載司機版</Typography>
                  <img src="/image/qrcode-android-driver.png" alt="Google Play" style={{width: '100%'}}/>
                  <img src="/image/download-android.png" alt="Google Play" style={{width: '100%', cursor: 'pointer'}}
                       onClick={()=>{
                         window.open('https://play.google.com/store/apps/details?id=com.doolaku.driver', '_blank');
                       }}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Home;
